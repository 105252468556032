<template>
  <v-img
    v-if="imgResp.src"
    v-once
    :aspect-ratio="aspectRatio"
    :gradient="HERO_GRADIENT"
    :sizes="imgResp.sizes"
    :src="imgResp.src"
    :srcset="imgResp.srcset"
    alt="Background image for the why section"
    class="home-why-agile-section"
    content-class="fill-height my-auto py-4"
    cover
    style="position: relative"
  >
    <div
      class="bg-background home-why-agile-clip-path-top"
      style="position: absolute; top: -1px"
    ></div>

    <v-container class="home-why-agile-row">
      <TitleParagraphRow class="text-white" :value="value" />

      <v-row v-if="value.items && value.items.length" justify="center">
        <v-col
          v-for="item in value.items"
          :key="item.id"
          align="center"
          cols="12"
          md="3"
          lg=""
          style="max-width: 380px"
        >
          <v-icon
            v-if="typeof item.icon === 'string'"
            :icon="`hdd:${item.icon}`"
            class="mb-4"
            color="white"
            color2="light-blue"
            size="80"
          />

          <div v-if="item.title" class="text-h6 text-center text-white">
            <StyledText :value="item.title" />
          </div>
        </v-col>
      </v-row>

      <v-row v-if="value.cta" justify="center">
        <v-col align="center" cols="12" md="8">
          <div
            v-if="value.cta.title"
            class="text-body-1 text-white font-weight-bold my-6"
          >
            <StyledText
              :value="value.cta.title"
              em-class="text-decoration-underline"
            />
          </div>

          <v-btn
            v-if="value.cta.action && value.cta.href"
            :append-icon="mdiArrowRight"
            :href="value.cta.href"
            class="text-capitalize"
            color="white"
            exact
            rounded="pill"
            size="large"
            >{{ value.cta.action }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <div
      class="bg-background home-why-agile-clip-path-bottom"
      style="position: absolute; bottom: -1px"
    ></div>
  </v-img>
</template>

<script setup>
import { toRef } from 'vue'
import { mdiArrowRight } from '@mdi/js'
import { HERO_GRADIENT } from '#root/lib/consts'
import { useImgResponsive } from '#root/lib/img'

const props = defineProps({
  value: {
    required: true,
    type: Object
  }
})

const aspectRatio = 16 / 9
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio,
    image: props.value.background_image
  }))
)
</script>

<style lang="scss">
@use '../settings';

// SEE: https://codepen.io/enbee81/pen/yLyrmyg
// SEE: https://bennettfeely.com/clippy/
.home-why-agile-section {
  --tan: 0.158384440324536; // 9deg
  --clip-offset: calc(100vw * var(--tan));
}

.home-why-agile-clip-path-top {
  height: calc(var(--clip-offset));
  width: 100%;
  z-index: 0;

  clip-path: polygon(100% 0, 0 var(--clip-offset), 0 0);
  -webkit-clip-path: polygon(100% 0, 0 var(--clip-offset), 0 0);
}

.home-why-agile-clip-path-bottom {
  height: calc(var(--clip-offset));
  width: 100%;
  z-index: 0;

  clip-path: polygon(0 100%, 100% calc(100% - (var(--clip-offset))), 100% 100%);
  -webkit-clip-path: polygon(
    0 100%,
    100% calc(100% - (var(--clip-offset))),
    100% 100%
  );
}

.home-why-agile-row {
  padding-bottom: calc(var(--clip-offset)) !important;
  padding-top: calc(var(--clip-offset)) !important;
}
</style>
